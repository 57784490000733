import { defineComponent } from '@vue/composition-api';
import { ref } from 'vue';
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import { QRCODE_LIMIT, QRCODE_FETCH } from '../../http/api';
export default defineComponent({
  setup() {
    const checked = ref(true);
    const city1Show = ref(false);
    const city1Name = ref('');
    const city1Value = ref('');
    const confirmShow = ref(false);
    const isConfirm = ref(false);
    const cascaderValue = ref('');
    const form = ref(null);
    const limit = ref(3);
    const qr_code = ref("");
    QRCODE_LIMIT().then(res => {
      Toast.clear();
      const data = res.data.data;
      limit.value = data.limit;
    }); // 全部选项选择完毕后，会触发 finish 事件

    const city1OnFinish = selectedOptions => {
      city1Show.value = false;
      city1Name.value = selectedOptions[1].name;
      city1Value.value = selectedOptions[1].code;
    };

    const city1OnCancel = () => {
      city1Show.value = false;
    };

    const addCity = () => {};

    const onSubmit = values => {
      var formData = {
        city_code: city1Value.value
      };
      QRCODE_FETCH(formData).then(res => {
        Toast.clear();

        if (res.data.data.qr_code !== '') {
          limit.value = res.data.data.limit;
          qr_code.value = res.data.data.qr_code;
        } else {
          Toast.fail("获取失败");
        }
      });
    }; // 确认并继续


    function confirmed() {
      var _form$value;

      isConfirm.value = true;
      confirmShow.value = false;
      (_form$value = form.value) === null || _form$value === void 0 ? void 0 : _form$value.submit();
    }

    return {
      qr_code,
      limit,
      checked,
      city1Show,
      city1OnFinish,
      city1Value,
      city1Name,
      cascaderValue,
      areaList,
      onSubmit,
      confirmShow,
      confirmed,
      form,
      city1OnCancel,
      addCity
    };
  }

});